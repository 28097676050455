import React, { useState, useEffect, useRef } from 'react';

import Button from './base/Button';
import Text from './base/Text';
import Container from './Container';

const QuickFeature = ({ image, text }: { image: string, text: string }) => {
  
  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      alignItems: "center"
    }}>
      <img src={process.env.PUBLIC_URL + "/assets/" + image } style={{ width: "40px" }} />
      <Text size={18} weight={500}>
        {text}
      </Text>
    </div>
  );
};

export default QuickFeature;
