import React, { useState } from 'react';

import Button from './base/Button';
import Text from './base/Text';

const Logo = () => {

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "20px",
      cursor: "pointer"
    }}
      onClick={() => window.location.replace(process.env.REACT_APP_BASE_URL ?? "")}
    >
      <img style={{
        width: "65px",
        height: "65px"
      }} src={ process.env.PUBLIC_URL + "/assets/new_logo.png" } />
      <div>
        <Text size={35} weight={700} color="#4854F4">
          Coverflow
        </Text>
      </div>

    </div>
  );
};

export default Logo;
