import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { useCookies } from 'react-cookie';
import { Analytics } from '@vercel/analytics/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';

import Sidebar from "./components/Sidebar";
import AppHeader from "./components/AppHeader";
import BottomBar from "./components/BottomBar";
import LoadingSpinner from "./components/base/Spinner";
import PrivacyModal from "./components/PrivacyModal";
import TermsModal from "./components/TermsModal";

import Spacer from "./components/base/Spacer";
import Space from "./components/base/Space";
import Text from "./components/base/Text";
import "./LandingPage.css";
import ImageContainer from "./components/ImageContainer";
import AppFooter from "./components/AppFooter";
import MenuModal from "./components/MenuModal";
import Button from "./components/base/Button";
import MovingScroll from "./components/MovingScroll";
import Container from "./components/Container";
import EmployeeCard from "./components/EmployeeCard";
import QuickFeature from "./components/QuickFeature";
import CheckFeature from "./components/CheckFeature";
import Logo from "./components/Logo";

//             Measure your workflows in minutes, not hours or days


const integrationWords = ['Applied Epic', 'Vertafore AMS360', 'Gmail', 'Outlook'];

const LandingPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<string>("overview");
  const [clientId, setClientId] = useState<string | null>(null);
  const [privacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false);
  const [menuModalOpen, setMenuModalOpen] = useState<boolean>(false);
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);
  const [successMessage, setSuccessMessage] = useState<ReactNode | null>(null);

  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [fadeIn, setFadeIn] = useState<boolean>(false);
  const [currentWordIndex, setCurrentWordIndex] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Set a delay before starting the fade-in animation
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 200); // 1-second delay

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % integrationWords.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [integrationWords.length]);


  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => window.removeEventListener('resize', checkOrientation);
  }, []);

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    setScrollPosition(scrollTop);
    //console.log("SCROLL POSITION IS NOW ");
    //console.log(scrollTop);
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [errorMessage])

  useEffect(() => {
    if (successMessage) {
      toast.info(successMessage, {
        position: "top-center",
        closeOnClick: false,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [successMessage])

  // useEffect(() => {
  //   setSuccessMessage(
  //     <Text size={20} weight={600} color="white">
  //       At Applied Net? I'll buy you a beer. Text me <a href="sms:+12812240743?body=Hi!%20I'm%20at%20Applied%20Net%20and%20saw%20your%20site.%20Where's%20my%20drink?" target="_blank" style={{ color: "white "}}>here</a>, or come say hi <a href="https://maps.app.goo.gl/7N5gR94812JMgprq8" target="_blank" style={{ color: "white "}}>here</a>.
  //     </Text>
  //   )
  //   //         At Applied Net? Let's meet up! Text me <a href="sms:+12812240743?body=Hi!%20I'm%20at%20Applied%20Net%20and%20saw%20your%20site.%20Where's%20my%20drink?" target="_blank" style={{ color: "white "}}>here</a>, or come say hi <a href="https://maps.app.goo.gl/oD93YLTQydHpRDZL8" target="_blank" style={{ color: "white "}}>here</a>. <br/><br/>If you find me, I'll buy you a beer. (Really.)

  // }, [])

  /*
        <BottomBar setPrivacyModalOpen={setPrivacyModalOpen} setTermsModalOpen={setTermsModalOpen} displayRight={ page != "overview"} />
  
  <div style={{
          display: "flex",
          flexDirection: isPortrait ? "column" : "row",
          flexWrap: "wrap",
          gap: isPortrait ? "40px" : "80px",
          alignItems: isPortrait ? "center" : "flex-start",
          justifyContent: "center",
        }}>
        */

  return (
    <div className="landingPageBackground" onScroll={handleScroll}>
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        //position: "fixed",
        width: "calc(100% - 100px)",
        height: "100px",
        padding: "0px 50px",
        //top: 0,
        //left: 0,
        zIndex: 5,
      }}>
        <div style={{ transform: isPortrait ? "translateX(-20px)" : undefined }}>
          <Logo />
        </div>
        { isPortrait && (
          <img src={process.env.PUBLIC_URL + "/assets/menu.png"} style={{ width: "35px", height: "35px", cursor: "pointer" }} onClick={() => setMenuModalOpen(true)} /> 
        )}
        { !isPortrait && (
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "20px",
          }}>
            <Link to="#how-it-works" style={{ textDecoration: 'none' }}><Text size={16} weight={600} onClick={() => document.getElementById('how-it-works')?.scroll({behavior: 'smooth'})}>
              How it works
            </Text></Link>
            <Button color="#4455F3" width="150px" height="50px" onClick={() => window.open("https://calendly.com/matthewfastow/coverflow-demo", "_blank")}>
              <Text size={14} color="white">
                Request Demo
              </Text>
            </Button>
          </div>
        )}
      </div>
      <Space px={isPortrait ? 40 : 120} />
      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        justifyContent: "center",
        opacity: fadeIn ? 1 : 0,
        transition: 'opacity 0.9s ease-in'
      }}>
        <div style={{
          display: "flex",
          flexDirection: isPortrait ? "column" : "row",
          gap: isPortrait ? "40px" : "80px",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: isPortrait ? "90%" : "35%"
          }}>
            <Text size={55} weight={600} align={isPortrait ? "center" : "left"}>
              Your insurance outsourcing tasks, done faster
            </Text>
            <Text size={22}>
              Analyze policies and update your agency management system 96% quicker than Patra or ResourcePro
              {false && "Ditch manual AMS entry, delight customers with 24/7 service, and automate your client research process."}
            </Text>
            <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              //justifyContent: "center",
              alignItems: "center"
            }}>
              <Button color="#4455F3" width="150px" height="50px" onClick={() => window.open("https://calendly.com/matthewfastow/coverflow-demo", "_blank")}>
                <Text size={14} color="white">
                  Request Demo
                </Text>
              </Button>
              {!isPortrait && (
                <>
                  <Text size={18}>
                    or
                  </Text>
                  <Link to="#how-it-works" style={{ textDecoration: 'none' }}><Text size={18} color="blue" onClick={() => document.getElementById('how-it-works')?.scroll({behavior: 'smooth'})}>
                    learn more
                  </Text></Link>
                </>
              )}
              
            </div>
          </div>
          <div style={{ width: isPortrait ? "90%" : "50%" }}>
            <MovingScroll isPortrait={isPortrait} scrollPosition={scrollPosition}>
              <ImageContainer image="new_vid_3.gif" />
            </MovingScroll>
          </div>
        </div>
        <Space px={150} />
        <Text size={40} weight={600} align="center">
          Trusted by Brokers At
        </Text>
        <Space px={40} />
        <div style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '120px' }}>
          <div style={{
            display: 'flex',
            animation: 'scroll 30s linear infinite',
            position: 'absolute',
            whiteSpace: 'nowrap',
            gap: "100px",
            transform: 'translate3d(0, 0, 0)'
          }}>
            <img src={process.env.PUBLIC_URL + "/assets/associated.webp"} style={{ height: "70px" }} /> 
            <img src={process.env.PUBLIC_URL + "/assets/barbary.png"} style={{ height: "70px" }} /> 
            <img src={process.env.PUBLIC_URL + "/assets/isu.png"} style={{ height: "70px" }} /> 
            <img src={process.env.PUBLIC_URL + "/assets/sparx.png"} style={{ height: "70px" }} /> 
            <img src={process.env.PUBLIC_URL + "/assets/twin.webp"} style={{ height: "70px" }} /> 
            <img src={process.env.PUBLIC_URL + "/assets/associated.webp"} style={{ height: "70px" }} /> 
            <img src={process.env.PUBLIC_URL + "/assets/barbary.png"} style={{ height: "70px" }} /> 
            <img src={process.env.PUBLIC_URL + "/assets/isu.png"} style={{ height: "70px" }} /> 
            <img src={process.env.PUBLIC_URL + "/assets/sparx.png"} style={{ height: "70px" }} /> 
            <img src={process.env.PUBLIC_URL + "/assets/twin.webp"} style={{ height: "70px" }} /> 
          </div>
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0) 15%, rgba(255,255,255,0) 85%, rgba(255,255,255,1))',
            pointerEvents: 'none'
          }}></div>
        </div>
        <Space px={150} />
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          justifyContent: "center"
        }}>
          <Text size={40} weight={600} align="center">
            End-to-end P&C processing that handles your AMS
          </Text>
          <Text size={18} align="center">
            Supercharge your brokerage with next-generation technology
          </Text>
          <Space px={0} />
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "25px",
            justifyContent: "center"
          }}>
            <div style={{ width: "275px" }}>
              <QuickFeature image="lightning.png" text="One-click AMS Updates"/>
              <Space px={20} />
              <QuickFeature image="complete2.png" text="Carrier Site Integrations"/>
            </div>
            <div style={{ width: "275px" }}>
              <QuickFeature image="integration.png" text="Document Retrieval"/>
              <Space px={20} />
              <QuickFeature image="create.png" text="Certificate Processing"/>
            </div>
          </div>
        </div>
      </div>
      <div id="how-it-works"></div>
      <Space px={200} />
      <div style={{
        display: "flex",
        flexDirection: isPortrait ? "column-reverse" : "row",
        gap: isPortrait ? "40px" : "80px",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <div style={{ width: isPortrait ? "90%" : "50%" }}>
          <MovingScroll isPortrait={isPortrait} scrollPosition={scrollPosition}>
            <ImageContainer image="new_vid_2.gif" />
          </MovingScroll>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: isPortrait ? "90%" : "35%"
        }}>
          <Text size={40} weight={600} align={ isPortrait ? "center" : "left" }>
           Policy Checking and Proposals in Under 20 Minutes
          </Text>
          <CheckFeature text="Execute renewals in under an hour" />
          <CheckFeature text="Generate professional policy proposals with one click" />
          <CheckFeature text="Automatic E&O flagging" />
          <CheckFeature text="Enjoy our expert-level accuracy standards" />
        </div>
      </div>
      <Space px={150} />
      <div style={{
        display: "flex",
        flexDirection: isPortrait ? "column" : "row",
        gap: isPortrait ? "40px" : "80px",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: isPortrait ? "90%" : "35%"
        }}>
          <Text size={40} weight={600} align={isPortrait ? "center" : "left"}>
            Give your operations team the speed they deserve
          </Text>
        </div>
        <div style={{ width: isPortrait ? "90%" : "50%" }}>
          <MovingScroll isPortrait={isPortrait} scrollPosition={scrollPosition}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}>
              <EmployeeCard image="producer.png" title="Producers" subtitle="Compare 300-page policies in under 20 minutes" />
              <EmployeeCard image="csr.png" title="Account Managers" subtitle="Enjoy AMS updates that happen before you need to ask" />
              <EmployeeCard image="customer.png" title="Directors of Operations" subtitle="Give your service team back hours each day" />
            </div>
          </MovingScroll>
        </div>
      </div>
      <Space px={220} />
      <div style={{
        display: "flex",
        flexDirection: isPortrait ? "column" : "row",
        gap: isPortrait ? "40px" : "80px",
        alignItems: isPortrait ? "center" : "flex-start",
        justifyContent: "center",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: isPortrait ? "90%" : "35%"
        }}>
          <Text size={40} weight={600} align={isPortrait ? "center" : "left"}>
            But do you integrate with {" "}
            <span className="fade">{integrationWords[currentWordIndex] + "?"}</span>
          </Text>
          <Text size={18}>
            We integrate with all major AMS providers and email carriers. If you use a different system, we'll even add it for you—free of charge.
          </Text>
        </div>
        <div style={{ width: "370px" }}>
          <MovingScroll isPortrait={isPortrait} scrollPosition={scrollPosition}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "20px",
              transform: isPortrait ? undefined : "translateY(-40px)"
            }}>
              <div style={{ width: "175px" }}>
                <ImageContainer image="applied.webp" />
              </div>
              <div style={{ width: "175px" }}>
                <ImageContainer image="vertafore.png" />
              </div>
              <div style={{ width: "175px" }}>
                <ImageContainer image="gmail.png" />
              </div>
              <div style={{ width: "175px" }}>
                <ImageContainer image="outlook.png" />
              </div>
            </div>
          </MovingScroll>
        </div>
      </div>
      <Space px={300} />
      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "0px"
      }}>
        <div style={{
          width: "100%",
          height: "10px",
          backgroundColor: "blue"
        }} />
        <div style={{
          display: "flex",
          flexDirection: isPortrait ? "column" : "row",
          padding: isPortrait ? "50px" : "100px",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "white",
          gap: "50px",
          height: isPortrait ? undefined : "150px"
        }}>
          <div>
            <Text size={45} weight={600}>
              Don't ship your data overseas.
            </Text>
            <Text size={45} weight={600}>
              Process it right here.
            </Text>
          </div>
          <Button color="#4455F3" width="190px" height="75px" onClick={() => window.open("https://calendly.com/matthewfastow/coverflow-demo", "_blank")}>
            <Text size={18} color="white">
              Request Demo
            </Text>
          </Button>
        </div>


      </div>
      
      
      <AppFooter isPortrait={isPortrait} />

      <TermsModal setModalOpen={setTermsModalOpen} modalOpen={termsModalOpen} />
      <PrivacyModal setModalOpen={setPrivacyModalOpen} modalOpen={privacyModalOpen} />
      <MenuModal setModalOpen={setMenuModalOpen} modalOpen={menuModalOpen} />
      <ToastContainer />
      <Analytics />
    </div>
  )
};

export default LandingPage;


/*

<Text size={40} weight={600} align="center">
        Why Leading Agencies Choose Coverflow
      </Text>
      <Space px={100} />
      <div style={{
        display: "flex",
        flexDirection: isPortrait ? "column" : "row",
        gap: isPortrait ? "40px" : "80px",
        alignItems: isPortrait ? "center" : "flex-start",
        justifyContent: "center",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: isPortrait ? "80%" : "25%"
        }}>
          <Text size={30} weight={600}>
            Security
          </Text>
          <Text size={18}>
            Your data is protected from everyone, including us. We invest in world-class security engineers.
          </Text>
          <div style={{ width: "250px" }}>
            <ImageContainer image="security.png" />
          </div>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: isPortrait ? "80%" : "25%"
        }}>
          <Text size={30} weight={600}>
            Accuracy
          </Text>
          <Text size={18}>
            Our system achieves the same accuracy as <b>expert CSRs</b>. Ask us about our rigorous standards and testing.
          </Text>
          <div style={{ width: "225px" }}>
            <ImageContainer image="accuracy.png" />
          </div>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: isPortrait ? "80%" : "25%"
        }}>
          <Text size={30} weight={600}>
            Speed
          </Text>
          <Text size={18}>
            Onboard with us in <b>under 2 hours</b>. Start saving <b>hundreds of hours</b> of employee time right after.
          </Text>
          <div style={{ width: "250px" }}>
            <ImageContainer image="speed.png" />
          </div>
        </div>
      </div>

      */