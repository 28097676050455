import React, { useState, useEffect, useRef, ReactNode } from 'react';

import Button from './base/Button';
import Text from './base/Text';

const Container = ({ children, justify, padding }: { children: ReactNode, justify?: string, padding?: string }) => {
  
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: justify ? justify : "center",
      backgroundColor: "white",
      borderRadius: "16px",
      padding: padding ? padding : "20px",
      boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.2)',
    }}>
      {children}
    </div>
  );
};

export default Container;
