import React, { useState, useEffect, useRef, ReactNode } from 'react';

import Button from './base/Button';
import Text from './base/Text';
import Container from './Container';

const EmployeeCard = ({ image, title, subtitle }: { image: string, title: string, subtitle: string }) => {
  
  return (
    <Container justify="flex-start" padding="15px">
      <div style={{
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        justifyContent: "flex-start",
        alignItems: "center"
      }}>
        <img src={process.env.PUBLIC_URL + "/assets/" + image } style={{ width: "100px", borderRadius: "16px" }}/>
        <div>
          <Text size={22} weight={600}>{title}</Text>
          <Text size={22}>{subtitle}</Text>
        </div>
      </div>
    </Container>
  );
};

export default EmployeeCard;
