import React, { useState, useEffect, useRef, ReactNode } from 'react';

const MovingScroll = ({ isPortrait, scrollPosition, children }: { isPortrait: boolean, scrollPosition: number, children: ReactNode }) => {
  const [distanceFromTop, setDistanceFromTop] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      setDistanceFromTop(rect.top);
    }
  }, [divRef])

  return (
    <div ref={divRef} style={{
      transition: 'transform 0.1s',
      transform: !isPortrait ? `translateY(${(distanceFromTop - scrollPosition) / 5 - 50}px)`: undefined, // Apply marginTop based on scroll position
    }}>
      {children}
    </div>
  );
};

export default MovingScroll;
