import React, { useState, useRef, useEffect, ReactNode } from "react";

const Text = ({ size, color, hoverColor, weight, align, font, children, onClick }: {
  size: string | number,
  color?: string,
  hoverColor?: string,
  weight?: number,
  align?: CanvasTextAlign,
  font?: string
  children: ReactNode,
  onClick?: () => void
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
 
  return (
    <div style={{
      textAlign: align ? align : "left",
      fontFamily: font ? font : "Montserrat",
      fontSize: size + "px",
      fontStyle: "normal",
      fontWeight: weight ? weight : 400,
      color: isHovered && onClick
        ? hoverColor ? hoverColor : "#4455f3"
        :  color ? color : "#16244f",
      cursor: onClick ? "pointer" : undefined,
      transition: "color 0.3s"
    }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      {children}
    </div>
  )
};

export default Text;
