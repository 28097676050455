import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import Select, { components } from 'react-select';
import { useGoogleLogin } from '@react-oauth/google';

import Title from "./base/Title";
import Text from "./base/Text";
import Login from "./Login";
import "./AppHeader.css";
import TextWithBorder from "./TextWithBorder";
import Spacer from "./base/Spacer";
import Button from "./base/Button";
import Logo from "./Logo";
import { Space } from "@mantine/core";

const AppFooter = ({ isPortrait }: {
  isPortrait: boolean,
}) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <div style={{
      display: "flex",
      flexDirection: isPortrait ? "column" : "row",
      justifyContent: isPortrait ? "flex-start" : "space-between",
      backgroundColor: "#f8f9fe",
      width: "calc(100% - 40px)",
      height: "300px",
      padding: "50px",
      gap: "40px",
      zIndex: 5,
    }}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "240px"
      }}>
        <Logo />
      </div>
      <div style={{
        display: "flex",
        flexDirection: "row",
        gap: "10%",
        marginRight: "100px"
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "100px"
        }}>
          <Text size={16} weight={500}>
            App
          </Text>
          <Space px={10} />
          <Text size={14} onClick={() => window.location.reload()}>
            Home
          </Text>
          <Text size={14} onClick={() => window.open("https://calendly.com/matthewfastow/coverflow-demo", "_blank")}>
            Get Demo
          </Text>
          <Link to="#how-it-works" style={{ textDecoration: 'none' }}><Text size={14} onClick={() => navigate("/#how-it-works")}>
            How it Works
          </Text></Link>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px"
        }}>
          <Text size={16} weight={500}>
            Contact
          </Text>
          <Space px={10} />
          <Text size={14} onClick={() => window.location.replace("mailto:sales@coverflow.tech")}>
            sales@coverflow.tech
          </Text>
          <Text size={14} onClick={() => window.location.replace("tel:+1-281-224-0743")}>
            281-224-0743
          </Text>
        </div>
      </div>
    </div>
  )
};

export default AppFooter;
