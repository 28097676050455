import React, { useState, useEffect, useRef } from 'react';

import Button from './base/Button';
import Text from './base/Text';
import Container from './Container';

const ImageContainer = ({ image, width }: { image: string, width?: string }) => {
  
  return (
    <Container>
      <img src={process.env.PUBLIC_URL + "/assets/" + image } style={{ width: width ? width : "100%" }} />
    </Container>
  );
};

export default ImageContainer;
