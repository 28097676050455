import React, { useState, useEffect, useRef } from 'react';

import Button from './base/Button';
import Text from './base/Text';
import Container from './Container';

const CheckFeature = ({ text }: { text: string }) => {
  
  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      gap: "15px",
      alignItems: "center"
    }}>
      <img src={process.env.PUBLIC_URL + "/assets/check.png" } style={{ width: "20px" }} />
      <Text size={22}>
        {text}
      </Text>
    </div>
  );
};

export default CheckFeature;


