import React, { useState, useRef, useEffect, ReactNode } from "react";

import './Modal.css';

const Modal = ({ children, setModalOpen, modalOpen, id, extraStyles }: { children: ReactNode, setModalOpen: (b: boolean) => void, modalOpen: boolean, id: string, extraStyles?: any }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const modal = document.getElementById(id);
    if (modalOpen) {
      if (modal) {
        modal.style.display = "flex";
        setTimeout(() => {
          modal.classList.add("modal-visible");
        }, 10);
      }
    } else {
      if (modal) {
        modal.classList.remove("modal-visible");
        modal.style.display = "none";
      }
    }
  }, [modalOpen, id]);
  
  useEffect(() => {
    function handleClickOutside(e) {
      if (containerRef.current !== null) {
        let containsElement = e.composedPath().includes(containerRef.current as EventTarget);
        if (!containsElement) {
            setModalOpen(false);
        }
      }
    }

    let timer;
    if (modalOpen) {
      timer = setTimeout(() => {
          window.addEventListener('click', handleClickOutside);
      }, 10);
    }
    return () => {
      clearTimeout(timer); // Ensure to clear the timeout if component unmounts before timeout completes.
      window.removeEventListener('click', handleClickOutside);
    };
  }, [modalOpen]);

  const containerStyle = {
    position: "relative",
    display: "flex",
    width: "min(550px, 70vw)",
    padding: "40px 24px 32px 24px",
    flexDirection: "column",
    gap: "20px 8px",
    borderRadius: "16px",
    border: "1px solid #ffffff",
    background: "#000000",
    zIndex: 3,
    backdropFilter: "none",
    ...extraStyles
  };

  /*
        <div style={{
        position: "fixed",
        zIndex: 1,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backdropFilter: "blur(2px)"
      }}>
      </div>*/

  return (
    <>
    <div
      id={id}
      className="modal"
      style={{
        display: "none",
        position: "fixed",
        zIndex: 10,
        left: "0",
        top: "0",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div className="backdrop"></div>
      <div ref={containerRef} style={containerStyle}>
        {children}
      </div>
      
    </div>

    </>
  )
};

export default Modal;
